
import { GetInvitesItem, GetInvitesResponse, GetOnboardingInvitesItem } from "@/types/responses"
const ProvisioningEditDialog = () => import("@/components/dialogs/ProvisioningEditDialog.vue")
const LogDialog = () => import("@/components/dialogs/LogDialog.vue")
//const InvitationSettings = () => import("@/components/invitation/InvitationSettings.vue").then(m => m.default || m)
import { InvitationEnum, InvitationTypeEnum, InvitationStatusEnum } from "@/types/enums"
import { DataTableHeader } from "vuetify"
import Vue from "vue"
import { format, parseISO } from "date-fns"
const dateCreated = function(dtCreated: string): string {
	const dateObject = parseISO(dtCreated)
	return format(dateObject, "EEEE dd/MM yyyy '-' hh:mm")
}
import { GrowthbookFeatureFlags, VerjiGrowthBook } from "@/utilities/growthbook"

export default Vue.extend({
	name: "Invitation",
	components: {
		ProvisioningEditDialog,
		LogDialog,
	},
	async mounted() {
		this.getInvites()
	},
	data(): {
		search: string
		editing: boolean
		logging: boolean
		selectedItem: null | GetInvitesItem | GetOnboardingInvitesItem
		loading: boolean
		headers: DataTableHeader[]
		snackBar: boolean
		snackBarText: string
		enm: typeof InvitationEnum
		enmStatus: typeof InvitationStatusEnum
		ignoreCompleted: boolean
		allowOnboardingInvitesFlag: boolean
	} {
		return {
			search: "",
			editing: false,
			logging: false,
			selectedItem: null,
			loading: false,
			headers: [
				{ text: "Name/Phone", value: "displayName", sortable: true, filterable: true },
				{ text: "User Id", value: "matrixId", sortable: true, filterable: true },
				{ text: "Email", value: "emailAddress", sortable: true, filterable: true },
				{ text: "Status", value: "statusReadable", sortable: true, filterable: true },
				{
					text: "Created",
					value: "createdReadable",
					sortable: true,
					filterable: true
				},
				{ text: "Action", value: "action", sortable: false, filterable: false },
			],
			snackBar: false,
			snackBarText: "",
			enm: InvitationEnum,
			enmStatus: InvitationStatusEnum,
			ignoreCompleted: false,
			allowOnboardingInvitesFlag: false
		}
	},
	computed: {
		invitesInProgress(): Array<GetInvitesItem | GetOnboardingInvitesItem & { createdReadable: string, statusReadable: string}> {
			// Invites
			const res: Array<GetInvitesItem | GetOnboardingInvitesItem> = this.$vStore.state.provisioning.invites.filter((item: GetInvitesItem) => {
				if (this.ignoreCompleted) {
					return item.status !== "Completed" && item.status !== "InvitationSentAndJobCompleted" && item.status !== "ProvisioningCompleted"
				} else return true
			})
			let resultWithReadableProps = res.map(item => {
				(item as GetInvitesItem & { createdReadable: string, statusReadable: string}).createdReadable = this.dateCreated((item as GetInvitesItem).created);
				(item as GetInvitesItem & { createdReadable: string, statusReadable: string}).statusReadable = this.invitationStatus((item as GetInvitesItem))
				return item
			}) as Array<GetInvitesItem & { createdReadable: string, statusReadable: string} | GetOnboardingInvitesItem & { createdReadable: string, statusReadable: string}>

			// Onboarding invites
			if (this.allowOnboardingInvitesFlag){
				const res2 = this.$vStore.state.provisioning.onboardingInvites.filter((item: GetOnboardingInvitesItem) => {
					if (this.ignoreCompleted) {
						return item.registrationCompleted !== true || item.status !== InvitationStatusEnum.Completed
					} else return true
				})
				const resultWithReadableProps2 = res2.map(item => {
					(item as GetOnboardingInvitesItem & { createdReadable: string, statusReadable: string}).createdReadable = (item?.created && item?.created != "") ? this.dateCreated(item?.created as string) : "";
					(item as GetOnboardingInvitesItem & { createdReadable: string, statusReadable: string}).statusReadable = this.onboardingInvitationStatus(item)
					return item
				}) as Array< GetOnboardingInvitesItem & { createdReadable: string, statusReadable: string}>
				
				// We need to duplicate this data because the table we are currently using does not support conditional data per row
				resultWithReadableProps2.forEach(e => {
					e.emailAddress = e?.email;
					e.isOnboardingInvite = true;
					e.displayName = e?.phoneNumber;
				})

				// Append if feature is enabled
				resultWithReadableProps = resultWithReadableProps.concat(resultWithReadableProps2);
			}
			
			return resultWithReadableProps;
		},
	},
	methods: {
		async getAllowOnboardingInvitesFlag(){
            this.allowOnboardingInvitesFlag = VerjiGrowthBook.getInstance().isOn(GrowthbookFeatureFlags.OnboardingInvites)
		},
		clearSearch(): void {
			this.search = ""
		},
		dateCreated(dtCreated: string): string {
			const dateObject = parseISO(dtCreated)
			return format(dateObject, "EEEE dd/MM yyyy '-' HH:mm")
		},
		itemRowBackgroundStyle: function(item: GetInvitesItem | GetOnboardingInvitesItem) {
			if (item.status === InvitationStatusEnum.Started) return "inprogress"
			else if (item.status === InvitationStatusEnum.AwaitingVerification) return "inprogress"
			else if (item.status === InvitationStatusEnum.InvitationSent) return "inprogress"
			else if (item.status === InvitationStatusEnum.Abandoned) return "error"
			else if (item.status === InvitationStatusEnum.Cancelled) return "error"
			else if (item.status === InvitationStatusEnum.InvitationSentAndJobCompleted) return "completed"
			else if (item.status === InvitationStatusEnum.ProvisioningCompleted) return "completed"
			else if (item.status === InvitationStatusEnum.Completed) return "completed"
			else if (item.status === InvitationStatusEnum.AccessControlUpdated) return "inprogress"
			else if (item.status === InvitationStatusEnum.OrganizationCreated) return "inprogress"
			else if (item.status === InvitationStatusEnum.AccountCreated) return "inprogress"
			else if (item.status === InvitationStatusEnum.VerificationFailed) return "error"

			else if ((item as GetOnboardingInvitesItem)?.registrationCompleted) return "completed"
			else if ((item as GetOnboardingInvitesItem)?.status === InvitationStatusEnum.Failed) return "error"
			else if ((item as GetOnboardingInvitesItem)?.isOnboardingInvite) return "inprogress"
			
			else return "error"
		},
		invitationType(item: GetInvitesItem): string {
			if (item.invitationType === InvitationTypeEnum.InviteNew) return "New user"
			else if (item.invitationType === InvitationTypeEnum.InviteExisting) return "Existing user"
			else return item.invitationType
		},
		invitationStatus(item: GetInvitesItem): string {
			if (item.status === InvitationStatusEnum.Started) return "Invitation started"
			else if (item.status === InvitationStatusEnum.AwaitingVerification) return "Awaiting verification"
			else if (item.status === InvitationStatusEnum.InvitationSent) return "Invitation sent"
			else if (item.status === InvitationStatusEnum.Abandoned) return "Invitation abondoned"
			else if (item.status === InvitationStatusEnum.Cancelled) return "Invitation cancelled"
			else if (item.status === InvitationStatusEnum.InvitationSentAndJobCompleted) return "Completed"
			else if (item.status === InvitationStatusEnum.ProvisioningCompleted) return "Provisioning completed"
			else if (item.status === InvitationStatusEnum.Completed) return "Completed"
			else if (item.status === InvitationStatusEnum.AccessControlUpdated) return "Access control updated"
			else if (item.status === InvitationStatusEnum.OrganizationCreated) return "Organization created"
			else if (item.status === InvitationStatusEnum.AccountCreated) return "Account created"
			else return item.status
		},
		onboardingInvitationStatus(item: GetOnboardingInvitesItem): string {
			if(item.status == InvitationStatusEnum.Failed) return "Failed"
			else if (item.status === InvitationStatusEnum.Started) return "Invitation started"
			else if (item.status == InvitationStatusEnum.Pending) return "Pending"
			else if (item.status == InvitationStatusEnum.Completed) return "Completed"
			else if (
				item.status == InvitationStatusEnum.AspNetUserCreated ||
				item.status == InvitationStatusEnum.MatrixUserCreated ||
				item.status == InvitationStatusEnum.DehydrationDeviceCreated ||
				item.status == InvitationStatusEnum.RoomCreated ||
				item.status == InvitationStatusEnum.RoomJoined ||
				item.status == InvitationStatusEnum.VmxUserCreated ||
				item.status == InvitationStatusEnum.ClientCreated ||
				item.status == InvitationStatusEnum.PersonAddedToClient) return "User created"
			else if (item.status == InvitationStatusEnum.EmailPushedToSynapse) return "Sending registration email"
			else if (item.status == InvitationStatusEnum.RegistrationEmailSent) return "Registration email sent"
			else if (item.status == InvitationStatusEnum.UserAlreadyExists) return "User already exists"
			else return item.status
		},
		showSnackBar(childMsg: string): void {
			this.snackBarText = childMsg
			this.snackBar = true
		},
		editItem(item: GetInvitesItem): void {
			this.selectedItem = Object.assign({}, item)
			this.editing = true
			this.logging = false
		},
		reinviteOnboardingInvitation(item: GetOnboardingInvitesItem): void {
			this.selectedItem = Object.assign({}, item)
			this.editing = true
			this.logging = false
		},
		showLog(item: GetInvitesItem): void {
			this.selectedItem = Object.assign({}, item)
			this.editing = false
			this.logging = true
		},
		refresh(): void {
			this.logging = false
			this.editing = false
			this.getInvites()
		},
		async refreshInvites(): Promise<void> {
			this.loading = true
			try {
				await this.getAllowOnboardingInvitesFlag();
				await this.$vStore.dispatch("provisioning/updateInvites", { allowOnboardingInvitesFlag: this.allowOnboardingInvitesFlag})
			} catch (e) {
				console.warn("Could not find any invites for this customer", e)
			}
			this.loading = false
		},
		async cancel() {
			this.logging = false
			this.editing = false
		},
		async getInvites(): Promise<void> {
			if (this.loading) return
			this.loading = true
			try {
				await this.getAllowOnboardingInvitesFlag();
				await this.$vStore.dispatch("provisioning/updateInvites", { allowOnboardingInvitesFlag: this.allowOnboardingInvitesFlag})
			} catch (e) {
				console.warn("Could not find any invites for this customer", e)
			}
			this.loading = false
		},
	},
})
